import { z } from 'zod';
import { moneySchema } from '../schemas';

export const getWithdrawalResponseSchema = z.object({
  withdrawnAmount: moneySchema,
  customerCosts: z.object({
    total: moneySchema,
    interest: moneySchema,
    principal: moneySchema,
    outstandingPrincipalBalance: moneySchema,
    totalPayments: z.number(),
    withdrawalFee: moneySchema.optional(), // TODO: Delete withdrawalFee (FE will use it from fees object) once the backend is updated (FCSE-5821)
    fees: z
      .object({
        withdrawalFee: z.number().optional(),
        setupFee: z.number().optional(),
        totalInstallmentFees: z.number().optional()
      })
      .optional() // TODO: Delete optional once the backend is updated (FCSE-5821)
  }),
  companyCosts: z.object({
    total: moneySchema,
    funding: moneySchema,
    lossProvision: moneySchema,
    servicing: moneySchema,
    labour: moneySchema
  }),
  companyProfit: moneySchema
});
